import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { logGoogleEvent, idioma, langPath, langUrl } from './global/analytics';
import VideoHandler from './global/videoHandler';
import { detectMob, detectIOS, detectSafari } from './global/ismobile';
import { Global, textOnOver, lottieOnOver } from './global/global';
const global = Global();

const videoHandler = VideoHandler();
videoHandler.startEventClick();
// ######################### KID INFO MODAL #########################
let infoLottie

let prevScrollStatus;

//Detect mobile:
let ismobile = detectMob();
let isIOS = detectIOS();
let isSafari = detectSafari();
// let isIOS = true;

if(isIOS && window.innerWidth < 780){
  document.querySelector('#kid-wrapper').style = 'bottom: -40px;';
  document.querySelector('#marqueeWrapper').style = 'bottom: -40px;';
}

// Show muestra los modales de los niños secundarios:
const show = (data) => {
  ////console.log('show  ------>  ',data);

  var values = data.split('_');

  let infoTitle = document.querySelector('#info-title');
  let infoSubtitle = document.querySelector('#info-subtitle');
  let infoImage = document.querySelector('#info-image');
  let infoBase = document.querySelector('.info-base');
  let infoArmor;

  let image = values[0];
  infoImage.setAttribute('kid',values[0]);
  infoTitle.textContent = values[1];
  infoSubtitle.innerHTML = values[2];
  let text = data;
  switch(image) {
    case '1':
      infoArmor = "src/assets/image/lottie/armaduras/armor1.json"
      infoBase.style.transform = "translate(0px, 0px)"
      infoImage.src = langPath() + "src/assets/image/webp/kid1.webp"
      break;
      case '2':
      infoImage.src = langPath() + "src/assets/image/webp/kid2.webp"
      infoArmor = "src/assets/image/lottie/armaduras/armor2.json"
      infoBase.style.transform = "translate(0px, -150px)"
      break;
      case '3':
      infoImage.src = langPath() + "src/assets/image/webp/kid3.webp"
      infoArmor = "src/assets/image/lottie/armaduras/armor3.json"
      infoBase.style.transform = "translate(0px, -100px)"
      break;
      case '4':
      infoImage.src = langPath() + "src/assets/image/webp/kid4.webp"
      infoArmor = "src/assets/image/lottie/armaduras/armor4.json"
      infoBase.style.transform = "translate(0px, -100px)"
      break;
      case '5':
      infoImage.src = langPath() + "src/assets/image/webp/kid5.webp"
      infoArmor = "src/assets/image/lottie/armaduras/armor5.json"
      infoBase.style.transform = "translate(0px, 0px)"
      break;
    default:
      // código a ejecutar si data no es igual a ningún caso
  }
  
  if(infoLottie != null || infoLottie != undefined){
    infoLottie.destroy();
  }
  infoLottie = lottie.loadAnimation({
    container: document.querySelector("#info-lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + infoArmor,
  });
  gsap.to('.info-modal', {
    display: 'flex',
    autoAlpha: 1,
    duration: 0.5,
    OnComplete: () => {
      prevScrollStatus = document.body.style.overflowY;
      document.body.style.overflowY="hidden";
    }
  });;


}
// ######################### END KID INFO MODAL #########################

// ######################### LISTENERS #########################

document.addEventListener('click', (e) => {
  // //console.log('CLICK',e.target);
  const target = e.target;
  if(target.matches(".marquee-item")){
    ////console.log('click show',target.dataset.ide );
    //Track click child:
    switch (target.dataset.ide){
      case "1":
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'niño': 1, 'idioma': idioma()});
      break;
      case "2":
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'niño': 2, 'idioma': idioma()});
      break;
      case "3":
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'niño': 3, 'idioma': idioma()});
      break;
      case "4":
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'niño': 4, 'idioma': idioma()});
      break;
      case "5":
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'niño': 5, 'idioma': idioma()});
      break;
    }
    show(target.dataset.value);
  }
  
  if(target.matches("#info-close")){
    gsap.to('.info-modal', {
      display: 'none',
      autoAlpha: 0,
      duration: 0.5,
      OnComplete: () => {
        document.body.style.overflowY=prevScrollStatus;
      }
    });
  }

  if(target.matches("#index-inner-form-info-btn")){
    ////console.log('click que-es-afe');
    global.logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'inner-form', 'idioma': global.idioma()});
    gsap.to(document.body, {
      opacity: 0,
      duration: .5, 
      onComplete: function() {
        var currentUrl = window.location.hostname;
        let url= `https://redesafe.org/armaduras-emocionales/${langUrl()}que-es-afe/`;

        if(currentUrl.includes("localhost")){
          url = `http://localhost:3300/${langUrl()}que-es-afe/`;
        }
        window.location.href = url;
      }
    });  
  }

  // if(target.matches("#index-modal-form-info-btn")){
  //   //console.log('click que-es-afe');
  //   global.logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'modal-form', 'idioma': global.idioma()});
  //   gsap.to(document.body, {
  //     opacity: 0,
  //     duration: .5, 
  //     onComplete: function() {
  //       var currentUrl = window.location.hostname;
  //       console.log(langUrl());
  //       let url= `https://redesafe.org/armaduras-emocionales/${langUrl()}que-es-afe/`;

  //       if(currentUrl.includes("localhost")){
  //         url = `http://localhost:3300/${langUrl()}que-es-afe/`;
  //       }
  //       // window.location.href = url;
  //     }
  //   });  
  // }

  if(target.matches("#lang-eu")){
    //console.log('click lang-eu');
    logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'eu', 'idioma': idioma()});
    let currentUrl = window.location.hostname;
    let url= "https://redesafe.org/armaduras-emocionales/eus";

    if(currentUrl.includes("localhost")){
      url = "http://localhost:3300/eus"
    }
    window.location.href = url;
  }

  if(target.matches("#lang-ca")){
    //console.log('click lang-ca');
    logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'ca', 'idioma': idioma()});
    let currentUrl = window.location.hostname;
    let url= "https://redesafe.org/armaduras-emocionales/cat";

    if(currentUrl.includes("localhost")){
      url = "http://localhost:3300/cat"
    }
    window.location.href = url;
  }

  if(target.matches("#lang-es")){
    //console.log('click lang-ca');
    logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'es', 'idioma': idioma()});
    window.location.href = "/";    let currentUrl = window.location.hostname;
    let url= "https://redesafe.org/armaduras-emocionales/";

    if(currentUrl.includes("localhost")){
      url = "http://localhost:3300/"
    }
    window.location.href = url;  }

  

})


// ######################### LOTTIES #########################

const eyeLotties = document.querySelectorAll('.eye-lottie');

eyeLotties.forEach(function(el) {
  const parent = el.parentNode.parentNode;
  lottieOnOver(parent, parent.querySelector('.eye'), el);
});

if(!ismobile && window.innerWidth > 950){
  // lottieOnOver('#video-mute', '#mute-static', '#mute-lottie');
  // lottieOnOver('#video-skip', '#skip-static', '#skip-lottie');
  lottieOnOver('#menu-form-submit', '#inner-send-static', '#inner-send');
  lottieOnOver('#menu-form-submit', '#inner-mark-static', '#inner-mark-lottie');
  textOnOver('.start-text', '.video-start');
  textOnOver('.skip-text', '#video-skip');
  textOnOver('.font-cta', '.font-cta-wrap');
}

let lotties = [];

// Explotions:
// Load only if not IOS

let lottieHeadExp;
let lottieBodyExp;
let lottieLArmExp;
let lottieRArmExp;
let lottieRLegExp;
let lottieLLegExp;

if(!isIOS){
  lottieHeadExp = lottie.loadAnimation({
    container: document.querySelector("#headExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_Cabeza_Exp.json"
  });
  lotties.push(lottieHeadExp);
  
  lottieBodyExp = lottie.loadAnimation({
    container: document.querySelector("#bodyExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_Cuerpo_Exp.json",
  });
  lotties.push(lottieBodyExp);
  
  lottieLArmExp = lottie.loadAnimation({
    container: document.querySelector("#lArmExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_B_Izq_Exp.json",
  });
  lotties.push(lottieLArmExp);
  
  lottieRArmExp = lottie.loadAnimation({
    container: document.querySelector("#rArmExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_B_Dch_Exp.json",
  });
  lotties.push(lottieRArmExp);
  
  lottieRLegExp = lottie.loadAnimation({
    container: document.querySelector("#rLegExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_P_Dch_Exp.json",
  });
  lotties.push(lottieRLegExp);
  
  lottieLLegExp = lottie.loadAnimation({
    container: document.querySelector("#lLegExp"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: false,
    autoplay: false,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_P_Izq_Exp.json",
  });
  lotties.push(lottieLLegExp);

}

// Idles
//Lotties if not IOS

if(!isIOS){
  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#headIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_Cabeza.json",
  }));

  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#bodyIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_Cuerpo.json",
  }));

  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#lArmIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_B_Izq.json",
  }));

  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#rArmIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_B_Dch.json",
  }));

  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#lLegIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_P_Izq.json",
  }));
  
  lotties.push(lottie.loadAnimation({
    container: document.querySelector("#rLegIdle"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras/Afe_armadura_P_Dch.json",
  }));
  
}else{
  // //console.log('load image armor');
  document.getElementById("headIdleStatic").classList.remove('invisible');
  // document.getElementById("headIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_cabeza.png' />`;
  // document.getElementById("headIdle").style = "width: 100%, height: 100%"
  
  document.getElementById("bodyIdleStatic").classList.remove('invisible');
  // document.getElementById("bodyIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_cuerpo.png' />`;
  // document.getElementById("bodyIdle").style = "width: 100%, height: 100%"
  
  document.getElementById("lArmIdleStatic").classList.remove('invisible');
  // document.getElementById("lArmIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_brazo_izquierdo.png' />`;
  // document.getElementById("lArmIdle").style = "width: 100%, height: 100%"
  
  document.getElementById("rArmIdleStatic").classList.remove('invisible');
  // document.getElementById("rArmIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_brazo_derecho.png' />`;
  // document.getElementById("rArmIdle").style = "width: 100%, height: 100%"
  
  document.getElementById("lLegIdleStatic").classList.remove('invisible');
  // document.getElementById("lLegIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_pierna_izquierda.png' />`;
  // document.getElementById("lLegIdle").style = "width: 100%, height: 100%"
  
  document.getElementById("rLegIdleStatic").classList.remove('invisible');
  // document.getElementById("rLegIdle").innerHTML= `<img src='${langPath()}src/assets/image/png/armadura_pierna_derecha.png' />`;
  // document.getElementById("rLegIdle").style = "width: 100%, height: 100%"
  ScrollTrigger.refresh();

}

switch(idioma()){
  case "eu":
  lottie.loadAnimation({
    container: document.querySelector("#logo1lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/armaduras-1102-EUS.json",
  });
  lottie.loadAnimation({
    container: document.querySelector("#logo2lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/emocionales-1102-EUS.json",
  });
  break;

  case "ca": 
    lottie.loadAnimation({
      container: document.querySelector("#logo1lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/armaduras-1102-CAT.json",
    });
    lottie.loadAnimation({
      container: document.querySelector("#logo2lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/emocionales-1102-CAT.json",
    });
  break;

  default:
    lottie.loadAnimation({
      container: document.querySelector("#logo1lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/armaduras-1102.json",
    });
    lottie.loadAnimation({
      container: document.querySelector("#logo2lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/emocionales-1102.json",
    });

}



let lottieflechascroll2 = lottie.loadAnimation({
  container: document.querySelector("#lottieflechascroll2"),
  renderer: "svg",
  rendererSettings: {
    filterSize: {
      width: '100%',
      height: '100%',
    }
  },
  loop: true,
  autoplay: true,
  path: langPath() + "src/assets/image/lottie/home/flecha_scroll.json",
});


if(!ismobile && window.innerWidth > 780){

  document.getElementById('cta-mark-lottie').classList.remove('hidden');
  document.getElementById('skip-lottie').classList.remove('hidden');
  document.getElementById('mute-lottie').classList.remove('hidden');

  document.getElementById('staticArrow2').classList.add('hidden');
  document.getElementById('staticArrow3').classList.add('hidden');
  document.getElementById('staticArrow4').classList.add('hidden');
  document.getElementById('staticArrow5').classList.add('hidden');
  document.getElementById('staticArrow6').classList.add('hidden');
  document.getElementById('staticArrow7').classList.add('hidden');
  document.getElementById('staticArrow9').classList.add('hidden');
  document.getElementById('lottieArrow2').classList.remove('hidden');
  document.getElementById('lottieArrow3').classList.remove('hidden');
  document.getElementById('lottieArrow4').classList.remove('hidden');
  document.getElementById('lottieArrow5').classList.remove('hidden');
  document.getElementById('lottieArrow6').classList.remove('hidden');
  document.getElementById('lottieArrow7').classList.remove('hidden');
  document.getElementById('lottieArrow9').classList.remove('hidden');

  
  document.getElementById('cta-mark-static').classList.add('hidden');
  document.getElementById('skip-static').classList.add('hidden');
  document.getElementById('mute-static').classList.add('hidden');


  document.getElementById('cta-mark-lottie').classList.remove('hidden');
  document.getElementById('skip-lottie').classList.remove('hidden');
  document.getElementById('mute-lottie').classList.remove('hidden');

  
}else{
  document.querySelector("#cta-mark-lottie").classList.remove('hidden');
  document.querySelector("#cta-mark-static").classList.add('hidden');
}



if(!ismobile && window.innerWidth > 780){
  
  lottie.loadAnimation({
    container: document.querySelector("#inner-pen"),
    renderer: "svg",
    filterSize: {
      width: '100%',
      height: '100%',
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/lapiz_azul.json"
  });

  lottie.loadAnimation({
    container: document.querySelector("#inner-mark-lottie"),
    renderer: "svg",
    filterSize: {
      width: '100%',
      height: '100%',
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/enviar_rayas.json"
  });

  lottie.loadAnimation({
    container: document.querySelector("#inner-send"),
    renderer: "svg",
    filterSize: {
      width: '100%',
      height: '100%',
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/enviar.json"
  });

  let lottieflechascroll = lottie.loadAnimation({
    container: document.querySelector("#lottieflechascroll"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/home/flecha_scroll.json",
  });
  lotties.push(lottieflechascroll);


  let lottiebolitahome1  = lottie.loadAnimation({
    container: document.querySelector("#lottiebolitahome1"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/home/ic_bolitas1_blanco.json",
  });
  lotties.push(lottiebolitahome1);

  let lottiebolitahome2 = lottie.loadAnimation({
    container: document.querySelector("#lottiebolitahome2"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/bolitas_1.json",
  });
  lotties.push(lottiebolitahome2);

  let lottiebolitahome3 = lottie.loadAnimation({
    container: document.querySelector("#lottiebolitahome3"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/home/ic_bolitas2_naranja.json",
  });
  lotties.push(lottiebolitahome3);

  let lottiebolitahome4 = lottie.loadAnimation({
    container: document.querySelector("#lottiebolitahome4"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/home/ic_bolitas1_blanco.json",
  });
  lotties.push(lottiebolitahome4);
    
  let muteLottie = lottie.loadAnimation({
    container: document.querySelector("#mute-lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/mute.json",
  });
  lotties.push(muteLottie);

  let skipLottie = lottie.loadAnimation({
    container: document.querySelector("#skip-lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/skip.json",
  });
  lotties.push(skipLottie);

  // let textPathLottie = lottie.loadAnimation({
  //   container: document.querySelector("#text-path"),
  //   renderer: "svg",
  //   rendererSettings: {
  //     filterSize: {
  //       width: '100%',
  //       height: '100%',
  //     }
  //   },
  //   loop: true,
  //   autoplay: true,
  //   path: langPath() + "src/assets/image/lottie/AFE_Texto_ZigZag.json",
  // });
  // lotties.push(textPathLottie);

}

const eyes = document.querySelectorAll(".eye-lottie");
eyes.forEach(elemento => {
  lotties.push(lottie.loadAnimation({
    container: elemento,
    // rendererSettings: {
    //   filterSize: {
    //     width: '100%',
    //     height: '100%',
    //   }
    // },
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: langPath() + "src/assets/image/lottie/eye.json",
  }));
});


// ######################### END LOTTIES #########################


// ######################### GSAP ANIMATIONS #########################

gsap.registerPlugin(ScrollTrigger);

// Armours



let headIdle = document.getElementById("headIdle");
let headExp = document.getElementById("headExp");
let bodyIdle = document.getElementById("bodyIdle");
let bodyExp = document.getElementById("bodyExp");
let lArmIdle = document.getElementById("lArmIdle");
let lArmExp = document.getElementById("lArmExp");
let rArmIdle = document.getElementById("rArmIdle");
let rArmExp = document.getElementById("rArmExp");
let lLegIdle = document.getElementById("lLegIdle");
let lLegExp = document.getElementById("lLegExp");
let rLegIdle = document.getElementById("rLegIdle");
let rLegExp = document.getElementById("rLegExp");
if(isIOS){
  headIdle = document.getElementById("headIdleStatic");
  bodyIdle = document.getElementById("bodyIdleStatic");
  lArmIdle = document.getElementById("lArmIdleStatic");
  rArmIdle = document.getElementById("rArmIdleStatic");
  lLegIdle = document.getElementById("lLegIdleStatic");
  rLegIdle = document.getElementById("rLegIdleStatic");
}

let headVisibility = {isVisible: true};
let bodyVisibility = {isVisible: true};
let lArmVisibility = {isVisible: true};
let rArmVisibility = {isVisible: true};
let lLegVisibility = {isVisible: true};
let rLegVisibility = {isVisible: true};

let headIdleON = true;
let lArmIdleON = true;
let bodyIdleON = true;
let rArmIdleON = true;
let lLegIdleON = true;
let rLegIdleON = true;


const armourOut = (armourIdle, armourExp, lottieExp, visibility) => {
  ////console.log('armourOut ---->', armourIdle.id);
  visibility.isVisible = false;
  // Hide idle lottie
  if(!isIOS){
    armourIdle.style.display = 'none'
  }else{
    armourIdle.classList.add('invisible');
  }
  // Show and play explosion lottie
  //Only if not IOS:
  if(!isIOS){
    armourExp.style.display = "block";
    lottieExp.setDirection(1);
    lottieExp.goToAndPlay(0, true);
    let callback = () => {armourOutCallback(armourIdle, armourExp, lottieExp, callback)};
    lottieExp.addEventListener("complete", callback );
  }
  
}

function armourOutCallback (armourIdle, armourExp, lottieExp, callback) {
  ////console.log('armourOutCallback ---->>>>', armourIdle.id);
  armourIdle.style.display = "none";
  armourExp.style.display ="none";
  lottieExp.removeEventListener("complete", callback);
}

const armourIn = (armourIdle, armourExp, lottieExp, visibility) => {
  ////console.log('armourIn ===== >', armourIdle.id);
  visibility.isVisible = true;
  // Show explosion lottie
  // Play explosion lottie reversed
  //Only if not IOS:
  if(!isIOS){
    armourExp.style.display = "block";
    lottieExp.setDirection(-1);
    lottieExp.goToAndPlay(lottieExp.totalFrames, true);
    // When animation ends hide it and show idle
    let callback = () => {armourInCallback(armourIdle, armourExp, lottieExp, callback, visibility)};
    lottieExp.addEventListener("complete", callback);
  }else{
    armourIdle.style.display = 'block;'
    armourIdle.classList.remove('invisible');
  }
  
}

function armourInCallback (armourIdle, armourExp, lottieExp, callback, visibility) {
  ////console.log('armourInCallback ==== >>>>', armourIdle.id);
  if (!visibility.isVisible) return;
  armourExp.style.display = "none";
  armourIdle.style.display = "block";
  lottieExp.removeEventListener("complete", callback);
}


// TEXTS

let textsTl = gsap.timeline({
  scrollTrigger: {
    trigger: "#triggerTextsTl",
    start: 'top bottom',
    end: 'bottom top-=4500px',
    // markers: true,
    scrub: 1,
    normalizeScroll: true
  },
});

textsTl.add(() => {
  ////console.log('empieza scroll');
  logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'scroll', 'valor': 0, 'idioma': idioma()});
});

if(isSafari){
  document.querySelectorAll("#ilu6, #ilu7, #ilu8, #ilu13, #ilu34, #ilu35, #ilu36, #ilu37").forEach(el => el.style.display = "none");
}

// Zoom cabeza
if(window.innerWidth < 780){
  textsTl.fromTo('#kid-base', { height: '190vmax', yPercent: 0, y: '4vmax' } ,{ height: '200vmax', yPercent: 0, y: '5vmax' });
}else if (window.innerWidth < 960){
  textsTl.fromTo('#kid-base', { height: '240vmax', yPercent: 0, y: '-13vmax' }, { height: '180vmax', yPercent: 0, y: '-15vmax' });
}else {
  textsTl.fromTo('#kid-base', { minHeight: '380%', yPercent: 0, y: '-13%' },{ minHeight: '270%', height: '270%', yPercent: 0, y: '-12%' });
  textsTl.set('#kid-base', {minHeight: 'unset'} );
} 

textsTl.to("#text1", { display: 'none', autoAlpha: 0, y: -30 });
textsTl.to("#text2", { display: 'none', autoAlpha: 0, y: -20 }, '<');


textsTl.to(".scroll-btn", { display: 'none', autoAlpha: 0, y: -10 }, 0);
textsTl.to("#ilu1", { display: 'none', autoAlpha: 0, y: -10 }, .3);
textsTl.to("#ilu3", { display: 'none', autoAlpha: 0, y: 0 }, .1);


// Cabeza
textsTl.fromTo("#text3", { y: 50 }, { display: 'flex', autoAlpha: 1, y: 0,
  onStart: () => { 
    armourOut(headIdle, headExp, lottieHeadExp, headVisibility);
    ////console.log('casco');
    logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'scroll', 'valor': 1, 'idioma': idioma()});
  }, 
  onReverseComplete: () => { armourIn(headIdle, headExp, lottieHeadExp, headVisibility) },
}, 1);

textsTl.fromTo("#ilu4", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .3);
textsTl.fromTo("#ilu5", { autoAlpha: 0, y: 0 }, { display: 'block',autoAlpha: 1, y: 0 }, .4);

if (!isSafari){
  textsTl.fromTo("#ilu6", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .5);
  textsTl.fromTo("#ilu7", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .6);
  textsTl.fromTo("#ilu8", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .7);
}else{}
textsTl.fromTo("#ilu9", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .6);
textsTl.fromTo("#ilu10", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, .5);
textsTl.fromTo("#ilu11", { autoAlpha: 0, y: 0 }, { display: 'block',autoAlpha: 1, y: 0 }, .6);

textsTl.to("#text3", { display: 'none', autoAlpha: 0, y: -50
}, 2.1);

// Niño mira arriba

textsTl.set('#kid-image-1', {className: "background invisible"}, 1.4);
textsTl.set('#kid-image-1b', {className: "background"}, 1.4);

textsTl.set('#kid-image-1b', {className: "background invisible"}, 1.5);
textsTl.set('#kid-image-1c', {className: "background"}, 1.5);

textsTl.set('#kid-image-1c', {className: "background invisible"}, 1.6);
textsTl.set('#kid-image-2', {className: "background"}, 1.6);

textsTl.to("#ilu4", { display: 'none', autoAlpha: 0, y: 0 }, 1.5);
textsTl.to("#ilu5", { display: 'none', autoAlpha: 0, y: 0 }, 1.9);

if (!isSafari){
  textsTl.to("#ilu6", { display: 'none', autoAlpha: 0, y: 0 }, 2.5);
  textsTl.to("#ilu7", { display: 'none', autoAlpha: 0, y: 0 }, 1.5);
  textsTl.to("#ilu8", { display: 'none', autoAlpha: 0, y: 0 }, 1.5);
  textsTl.fromTo("#ilu13", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 1.6);
  textsTl.to("#ilu13", { display: 'none', autoAlpha: 0, y: 0 }, 4);
}
textsTl.to("#ilu9", { display: 'none', autoAlpha: 0, y: 0 }, 1.5);
textsTl.to("#ilu10", { display: 'none', autoAlpha: 0, y: 0 }, 1.5);
textsTl.to("#ilu11", { display: 'none', autoAlpha: 0, y: 0 }, 2.3);


textsTl.fromTo("#ilu12", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 1.5);

textsTl.fromTo("#ilu14", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 1.7);
textsTl.fromTo("#ilu15", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 2.6);
textsTl.fromTo("#ilu16", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 1.9);
textsTl.fromTo("#ilu17", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 2.6);
textsTl.fromTo("#ilu18", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 2.1);
textsTl.fromTo("#ilu19", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 2.2);


textsTl.to('#lLegIdle', { className: 'idle'}, 2.1);
textsTl.to('#rLegIdle', { className: 'idle'}, 2.1);

// Zom brazos
if(window.innerWidth < 780){
  textsTl.to('#kid-base', { height: '150vmax', yPercent: 0, y: '9vmax' });
}else if (window.innerWidth < 960){
  textsTl.to('#kid-base', { height: '170vmax', yPercent: 0, y: '-12vmax' });
}else {
  textsTl.to('#kid-base', { height: '230%', yPercent: 0, y: '-13.89%' });
}


textsTl.set('#kid-image-2', {className: "background invisible"}, 2.1);
textsTl.set('#kid-image-1c', {className: "background"}, 2.1);

textsTl.set('#kid-image-1c', {className: "background invisible"}, 2.2);
textsTl.set('#kid-image-1b', {className: "background"}, 2.2);

textsTl.set('#kid-image-1b', {className: "background invisible"}, 2.3);
textsTl.set('#kid-image-1', {className: "background"}, 2.3);

// Brazos
textsTl.fromTo("#text4", { autoAlpha: 0, y: 50 }, { display: 'flex', autoAlpha: 1, y: 0, 
  onStart: () => { 
    armourOut(rArmIdle, rArmExp, lottieRArmExp, rArmVisibility); 
    //console.log('brazos');
    logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'scroll', 'valor': 2, 'idioma': idioma()});
  }, 
  onReverseComplete: () => { armourIn(rArmIdle, rArmExp, lottieRArmExp, rArmVisibility); } }, 2.6);

textsTl.to("#text4", { 
  onStart: () => { armourOut(lArmIdle, lArmExp, lottieLArmExp, lArmVisibility); }, 
  onReverseComplete: () => { armourIn(lArmIdle, lArmExp, lottieLArmExp, lArmVisibility); } }, 3.5);
  
textsTl.to("#text4", { display: 'none', autoAlpha: 0, y: -50 }, 4.5);


// Niño mira manos
textsTl.set('#kid-image-1', {className: "background invisible"}, 3.9);
textsTl.set('#kid-image-1b', {className: "background"}, 3.9);

textsTl.set('#kid-image-1b', {className: "background invisible"}, 4.1);
textsTl.set('#kid-image-3', {className: "background"}, 4.1);

textsTl.set('#kid-image-3', {className: "background invisible"}, 4.6);
textsTl.set('#kid-image-1c', {className: "background"}, 4.6);

textsTl.set('#kid-image-1c', {className: "background invisible"}, 4.8);
textsTl.set('#kid-image-1', {className: "background"}, 4.8);




// Cuerpo
textsTl.fromTo("#text5", { autoAlpha: 0, y: 50 }, { display: 'flex', autoAlpha: 1, y: 0, 
  onStart: () => { 
    armourOut(bodyIdle, bodyExp, lottieBodyExp, bodyVisibility);
    //console.log('cuerpo');
    logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'scroll', 'valor': 3, 'idioma': idioma()});}, 
  onReverseComplete: () => { 
    armourIn(bodyIdle, bodyExp, lottieBodyExp, bodyVisibility) 
  }},5);

textsTl.to("#ilu12", { display: 'none', autoAlpha: 0, y: 0 }, 4);
textsTl.to("#ilu14", { display: 'none', autoAlpha: 0, y: 0 }, 4);
textsTl.to("#ilu15", { display: 'none', autoAlpha: 0, y: 0 }, 4.3);
textsTl.to("#ilu16", { display: 'none', autoAlpha: 0, y: 0 }, 4);
textsTl.to("#ilu17", { display: 'none', autoAlpha: 0, y: 0 }, 4.6);
textsTl.to("#ilu18", { display: 'none', autoAlpha: 0, y: 0 }, 4);
textsTl.to("#ilu19", { display: 'none', autoAlpha: 0, y: 0 }, 4);

textsTl.to("#text5", { display: 'none', autoAlpha: 0, y: -50 }, 6.3);

// Zoom piernas
if(window.innerWidth < 780){
  textsTl.to('#kid-base', { height: '120vmax', yPercent: 0, y: '4vmax' });
}else if (window.innerWidth < 960){
  textsTl.to('#kid-base', { height: '150vmax', yPercent: 0, y: '-7vmax' });
}else if (window.innerWidth < 1920){
  textsTl.to('#kid-base', { height: '160%', yPercent: 0, y: '-11.11%' });
} else {
  textsTl.to('#kid-base', { height: '160%', yPercent: 0, y: '-11.11%' });
}

textsTl.fromTo("#ilu20", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 5); //compras blanco
textsTl.fromTo("#ilu21", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 4.7);
textsTl.fromTo("#ilu22", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 5.2);
textsTl.fromTo("#ilu23", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 4.5);
textsTl.fromTo("#ilu24", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 4.7);
textsTl.fromTo("#ilu25", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 4.8);
textsTl.fromTo("#ilu26", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 4.9);

textsTl.to("#ilu20", { display: 'none', autoAlpha: 0, y: 0 }, 6.2); //compras blanco
textsTl.to("#ilu21", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);
textsTl.to("#ilu22", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);
textsTl.to("#ilu23", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);
textsTl.to("#ilu24", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);
textsTl.to("#ilu25", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);
textsTl.to("#ilu26", { display: 'none', autoAlpha: 0, y: 0 }, 5.7);

// Piernas
textsTl.fromTo("#text6", { autoAlpha: 0, y: 50 }, { display: 'flex', autoAlpha: 1, y: 0, 
  onStart: () => { 
    armourOut(lLegIdle, lLegExp, lottieLLegExp, lLegVisibility);
    //console.log('piernas');
    logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'scroll', 'valor': 4, 'idioma': idioma()});
  }, 
  onReverseComplete: () => { armourIn(lLegIdle, lLegExp, lottieLLegExp, lLegVisibility)},
},  6.5);
textsTl.to("#text6", { 
  onStart: () => { armourOut(rLegIdle, rLegExp, lottieRLegExp, rLegVisibility); }, 
  onReverseComplete: () => { armourIn(rLegIdle, rLegExp, lottieRLegExp, rLegVisibility);},
}, 7);


textsTl.fromTo("#ilu28", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.5);
textsTl.fromTo("#ilu29", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.9);
textsTl.fromTo("#ilu30", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.4); //ganchito naranja
textsTl.fromTo("#ilu31", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.3); //regla verde
textsTl.fromTo("#ilu32", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 5.7);
textsTl.fromTo("#ilu33", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 5.8);


if (!isSafari){
textsTl.fromTo("#ilu34", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 5.9);
textsTl.fromTo("#ilu35", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6);
textsTl.fromTo("#ilu36", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.1);
textsTl.fromTo("#ilu37", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 6.2);

textsTl.to("#ilu34", { display: 'none', autoAlpha: 0, y: 0 }, 7.5);
textsTl.to("#ilu35", { display: 'none', autoAlpha: 0, y: 0 }, 7.5);
textsTl.to("#ilu36", { display: 'none', autoAlpha: 0, y: 0 }, 7.5);
textsTl.to("#ilu37", { display: 'none', autoAlpha: 0, y: 0 }, 7.5);
}

// Niño salta
textsTl.set('#kid-image-1', {className: "background invisible"}, 7.5);
textsTl.set('#kid-image-4', {className: "background"}, 7.5);

textsTl.set('#kid-image-4', {className: "background invisible"}, 7.7);
textsTl.set('#kid-image-5', {className: "background"}, 7.7);

textsTl.set('#kid-image-5', {className: "background invisible"}, 7.9);
textsTl.set('#kid-image-6', {className: "background"}, 7.9);

textsTl.set('#kid-image-6', {className: "background invisible"}, 8.1);
textsTl.set('#kid-image-5', {className: "background"}, 8.1);

textsTl.set('#kid-image-5', {className: "background invisible"}, 8.3);
textsTl.set('#kid-image-4', {className: "background"}, 8.3);

textsTl.set('#kid-image-4', {className: "background invisible"}, 8.5);
textsTl.set('#kid-image-1', {className: "background"}, 8.5);

textsTl.to("#ilu28", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);
textsTl.to("#ilu29", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);
textsTl.to("#ilu30", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);
textsTl.to("#ilu31", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);
textsTl.to("#ilu32", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);
textsTl.to("#ilu33", { display: 'none', autoAlpha: 0, y: 0 }, 8.5);

textsTl.to("#text6", { display: 'none', autoAlpha: 0, y: -50 }, 8.5);

textsTl.fromTo("#ilu38", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 8.6);
textsTl.fromTo("#ilu39", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 8.7);
textsTl.fromTo("#ilu40", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 8.8);
textsTl.fromTo("#ilu41", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 8.9);
textsTl.fromTo("#ilu42", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 9);

// Texto final
textsTl.fromTo("#text7", { autoAlpha: 0, y: 50 }, { display: 'flex', autoAlpha: 1, y: 0 }, 8.7);

textsTl.to("#headerbar", { autoAlpha: 1});

// ######################### END GSAP ANIMATIONS ######################### 


// ######################### MARQUEE ######################### 


const kidIntoMarqueeTl = () => {
  
  let anim = gsap.timeline()

  anim.to('#kid-base',{
    height: '130%',
    y: 0,
    yPercent: 0,
    duration: 0.5
  });

  return anim;
} 

textsTl.add(kidIntoMarqueeTl());


let initMarquee = () => {


  let marqueeWidth = document.querySelector('#marqueeWrapper').getBoundingClientRect().width;
  let marqueeHeight = document.querySelector('#marqueeWrapper').offsetHeight;
  let kidWidth = document.querySelector('#kid-2').offsetWidth;
  let easeOffset = 200;
  
  let movement = (marqueeWidth - window.innerWidth);
  let kidOffset = ((window.innerWidth/2) + (kidWidth/2)) / 2 - easeOffset;
  let scrollDistance = movement;
  
  //console.log('marqueeWidth: ' + marqueeWidth)
  //console.log('kidWidth: ' + kidWidth)
  //console.log('kidOffset: ' + kidOffset)
  //console.log('movement: ' + movement)
  
  gsap.to('#marqueeWrapper',
    {
      x: -movement,
      scrollTrigger: {
        trigger: "#marqueeWrapper",
        start: 'bottom bottom',
        end: `+=${scrollDistance}`,
        // markers: true,
        scrub: 1,
        pin: true,
        onLeave: () => {
          gsap.to("#text7", { display: 'none', autoAlpha: 0, y: 0 });
        },
        onEnterBack: () => {
          gsap.to("#text7", { display: 'block', autoAlpha: 1, y: 0, x: 0 });
        },
        normalizeScroll: true
      }
    },
  );
  
  
  let marqueeTl = gsap.timeline({
    scrollTrigger: {
      trigger: "#triggerTextsTl",
      start: 'top bottom',
      end: 'bottom top-=4500px',
      scrub: 1
    },
  });
  
  marqueeTl.to("#ilu38", { display: 'none', autoAlpha: 0, y: 0 }, 14);
  marqueeTl.to("#ilu39", { display: 'none', autoAlpha: 0, y: 0 }, 14.3);
  marqueeTl.to("#ilu40", { display: 'none', autoAlpha: 0, y: 0 }, 15.5);
  marqueeTl.to("#ilu41", { display: 'none', autoAlpha: 0, y: 0 }, 15.2);
  marqueeTl.to("#ilu42", { display: 'none', autoAlpha: 0, y: 0 }, 15);
  
  marqueeTl.fromTo("#ilu43", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 14.2);
  marqueeTl.fromTo("#ilu44", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 15);
  marqueeTl.fromTo("#ilu45", { autoAlpha: 0, y: 0 }, { display: 'block', autoAlpha: 1, y: 0 }, 15.5);
  
  gsap.to('#kid-base',
  {
    x: -movement,
    scrollTrigger: {
      trigger: "#triggerKid",
      start: `bottom+=${marqueeHeight + kidOffset} bottom`,
      end: `bottom+=${marqueeHeight + kidOffset + scrollDistance} bottom`,
      // markers: true,
      scrub: 1,
    }
  }
  );



  ScrollTrigger.create({
    trigger: "#innerForm",
    start: "bottom bottom",
    onEnter: () => {
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'footer', 'idioma': idioma()});
    } 
  });

  
} 

// ScrollTrigger.create({
//   trigger: "#thank-you-wrap",
//   start: 'bottom top',
//   end:'bottom bottom',
//   onEnter: () => {
//     //console.log('hardhide');
//     document.getElementById('cta').classList.add('hardhide');
//     document.getElementById('headerwrap').classList.add('hardhide');
//     document.getElementById('text7').classList.add('hardhide');
//   },
//   onEnterBack: () => {
//     document.getElementById('cta').classList.remove('hardhide');
//     document.getElementById('headerwrap').classList.remove('hardhide');
//     document.getElementById('text7').classList.remove('hardhide');
//   }
// });



// ######################### END MARQUEE ######################### 

function navigateToPage (){
  let currentUrl = window.location.hostname;
  let url= `https://redesafe.org/armaduras-emocionales/${langUrl()}que-es-afe/`;

  if(currentUrl.includes("localhost")){
    url = `http://localhost:3300/${langUrl()}que-es-afe/`;
  }
  window.location.href = url;

  logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'source': 'formulario 1', 'idioma': idioma()});
  
}

let btnsNavigateAfe = document.getElementsByClassName("navigateToAfe");

for (var i = 0; i < btnsNavigateAfe.length; i++) {
  btnsNavigateAfe[i].addEventListener('click', navigateToPage);
}


// ######################### SCROLL TO TOP #########################

window.onload = function() {
  window.scrollTo(0, 0);
  
  if (location.pathname.includes("que-es-afe")) {
    logGoogleEvent('visita', {'pagina': 'que es afe', 'idioma': idioma()});
  } else {
    logGoogleEvent('visita', {'pagina': 'home', 'idioma': idioma()});
  }

  //Change this to show or not intro video
  let showVideo = true;

  if(showVideo) {
    videoHandler.start();
  }else{
    videoHandler.showMain();
  }

}
window.onbeforeunload = function() {
  gsap.to(document.body, {
    opacity: 0,
    duration: .1
  });
  window.scrollTo(0, 0);
}


// ######################### IMAGE PRELOAD #########################

$(document).ready(function () {
  // Images loaded is zero because we're going to process a new set of images.
  var imagesLoaded = 0;
  // Total images is still the total number of <img> elements on the page.
  var totalImages = $("img").length;

  // Step through each image in the DOM, clone it, attach an onload event
  // listener, then set its source to the source of the original image. When
  // that new image has loaded, fire the imageLoaded() callback.
  $("img").each(function (idx, img) {
    $("<img>").on("load", imageLoaded).attr("src", $(img).attr("src"));
    $("<img>").on("error", imageError).attr("src", $(img).attr("src"));
  });

  // Do exactly as we had before -- increment the loaded count and if all are
  // loaded, call the allImagesLoaded() function.
  function imageLoaded() {
    imagesLoaded++;
    // //console.log('loaded: ' + imagesLoaded + ' | total:' + totalImages);
    if (imagesLoaded == totalImages) {
      allImagesLoaded();
    }
  }

  function imageError() {
    imagesLoaded++;
    // //console.log('error: ' + imagesLoaded + ' | total:' + totalImages);
    if (imagesLoaded == totalImages) {
      allImagesLoaded();
    }
  }

  function allImagesLoaded() {
    //console.log("ALL IMAGES LOADED");
    initAnimations()
    .then(() => {
      //console.log('ALL LOTTIES LOADED')
      initMarquee();
      ScrollTrigger.refresh();
      let loaderTl = gsap.timeline();
      loaderTl.to(document.body, {
        opacity: 1,
        duration: .5
      });
    })
    .catch((error) => {
      console.error(error)
    });
  }
});

// Return a promise that resolves to true once animation is loaded
async function animationLoaded (animation) {
  if (animation.isLoaded) {
    return true
  }
  
  return new Promise((resolve, reject) => {
    animation.addEventListener('DOMLoaded', () => {
      resolve(true)
    })
  })
}

// Return a promise that resolves to true once all animations are loaded
async function waitForAnimationsLoaded (animations) {
  await Promise.all(animations.map(animationLoaded))
}

async function initAnimations () {
  const animations = lottie.getRegisteredAnimations()
  await waitForAnimationsLoaded(animations)
}

// ######################### END IMAGE PRELOAD #########################
